import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import { makeStyles } from "@mui/styles";
import { styled } from "@mui/styles";
import {
  Select,
  FormControl,
  Grid,
  TextField,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";

import GoogleMap from "../../../shared/googleWithAutoComplete/GoogleMap";

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props) => (props.colorText ? props.colorText : "green"),
  },
  map: {
    width: "100%",
    height: "300px",
  },
}));

const Text = styled("div")({
  paddingTop: "0px !important",
  paddingBottom: "0px !important",
});

const generateTimeOptions = (startTime = "-01:30") => {
  const times = [];
  const [startHour, startMinute] = startTime.split(":").map(Number);
  let startMinutes = startHour * 60 + startMinute + 30; // Adding 30 minutes

  for (let i = 0; i < 48; i++) {
    let hour = Math.floor(startMinutes / 60);
    let minute = startMinutes % 60;
    if (hour < 24) {
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      times.push(`${hour}:${minute}`);
      startMinutes += 30;
    }
  }
  return times;
};

const FormFlight = (props) => {
  const classes = useStyles({ colorText: "red" });
  const [dataFrom, setDataFrom] = useState("");
  const [dataTo, setDataTo] = useState("");
  const [dataFromReturn, setDataFromReturn] = useState("");
  const [dataToReturn, setDataToReturn] = useState("");
  const [isDepartureDate, setIsDepartureDate] = useState(new Date());
  const [isReturnDate, setIsReturnDate] = useState(new Date());
  const [endTimeOptions, setEndTimeOptions] = useState(generateTimeOptions());
  const [endTimeReturnOptions, setEndTimeReturnOptions] = useState(
    generateTimeOptions()
  );

  const onChangePassenger = (e) => {
    if (
      props.getValues("numberOfPassengerReturn") === "" ||
      props.getValues("numberOfPassengerReturn") >
        props.getValues("numberOfPassenger") ||
      props.getValues("numberOfPassengerReturn") === undefined
    ) {
      props.setValue("numberOfPassengerReturn", e.target.value);
    }
  };

  const onChangeStartTime = (startTime) => {
    props.setValue("startTime", startTime);
  };

  const onChangeEndTime = (endTime) => {
    props.setValue("endTime", endTime);
  };

  const onChangeStartTimeReturn = (startTimeReturn) => {
    props.setValue("returnStartTime", startTimeReturn);
  };

  const onChangeEndTimeReturn = (endTimeReturn) => {
    props.setValue("returnEndTime", endTimeReturn);
  };

  const getDataFromGoogleMapFromPlace = (data) => {
    setDataFrom(data);
    props.setValue("fromPlace", data);
    setDataToReturn(data);
    props.setValue("toPlaceReturn", data);
  };
  const getDataFromGoogleMapToplace = (data) => {
    setDataTo(data);
    props.setValue("toPlace", data);
    setDataFromReturn(data);
    props.setValue("fromPlaceReturn", data);
  };

  const getDataFromGoogleMapFromReturnPlace = (data) => {
    setDataFromReturn(data);
    props.setValue("fromPlaceReturn", data);
  };
  const getDataFromGoogleMapToReturnplace = (data) => {
    setDataToReturn(data);
    props.setValue("toPlaceReturn", data);
  };

  useEffect(() => {
    const newEndTimeOptions = generateTimeOptions(props.getValues("startTime"));
    setEndTimeOptions(newEndTimeOptions);
  }, [props.watch("startTime")]);

  useEffect(() => {
    const newEndTimeReturnOptions = generateTimeOptions(
      props.getValues("returnStartTime")
    );
    setEndTimeReturnOptions(newEndTimeReturnOptions);
  }, [props.watch("returnStartTime")]);

  return (
    <div style={{ marginBottom: "20px" }}>
      <FormControl
        style={{ width: "100%", paddingLeft: "10px", margin: "15px 0px" }}
      >
        <Controller
          rules={{ required: true }}
          control={props.control}
          defaultValue="oneWay"
          name="flight"
          render={(propsInController) => (
            <RadioGroup
              style={{ flexDirection: "row" }}
              value={propsInController.field.value}
              onChange={(flight) => {
                propsInController.field.onChange(flight.target.value);
                props.setValue("flight", flight.target.value);
              }}
            >
              <FormControlLabel
                value="oneWay"
                control={
                  <Radio
                    className={classes.Radio}
                    checked={propsInController.field.value === "oneWay"}
                  />
                }
                label="เที่ยวเดียว"
              />
              <FormControlLabel
                value="twoWay"
                control={
                  <Radio
                    className={classes.Radio}
                    checked={propsInController.field.value === "twoWay"}
                  />
                }
                label="ไป-กลับ"
              />
            </RadioGroup>
          )}
        />
      </FormControl>
      {props.watch("flight") === "twoWay" && (
        <Text style={{ width: "100%" }}>
          ขาไป
          <Divider style={{ marginBottom: "20px" }} />
        </Text>
      )}
      <Grid
        container
        spacing={2}
        sx={(theme) => ({
          display: "flex",
          // justifyContent: "space-between",
          border: "1px solid #EEEEEE",
          backgroundColor: "#FBFCFC",
          boxShadow:
            "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

          marginBottom: "20px",
          padding: "25px 30px 25px 30px",
          // padding: "25px 30px 25px 30px",

          borderRadius: "15px",
          [theme.breakpoints.down("sm")]: {
            // padding: "25px 30px 25px 15px",
          },
          [theme.breakpoints.down("md")]: {
            // padding: "25px 30px 25px 15px",
          },
        })}
      >
        <Grid container spacing={2} style={{ height: "50%", width: "100%" }}>
          <Grid item xs={12} sm={6} lg={6}>
            <InputLabel style={{ marginBottom: "10px" }}>{"จุดรับ"}</InputLabel>
            <Grid item xs={12} sm={6} lg={12}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    placeholder="ระบุต้นทาง"
                    label="ต้นทาง"
                    variant="outlined"
                    error={props.errors.fromPlace ? true : false}
                    helperText={
                      props.errors.fromPlace ? "กรุณาเลือกจุดต้นทาง" : false
                    }
                    fullWidth
                    disabled
                    multiline
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    onBlur={(e) => {
                      if (
                        props.getValues("toPlaceReturn") === "" ||
                        props.getValues("toPlaceReturn") === undefined
                      ) {
                        props.setValue("toPlaceReturn", e.target.value);
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                  />
                )}
                name="fromPlace"
                control={props.control}
                rules={{ required: true }}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: "400px" }}>
              <GoogleMap
                name="fromPlace"
                inputProps={{ ...props.setValue("fromPlace", dataFrom) }}
                value={dataFrom}
                handleGetDataFromGoogleMap={getDataFromGoogleMapFromPlace}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <InputLabel style={{ marginBottom: "10px" }}>{"จุดส่ง"}</InputLabel>
            <Grid item xs={12} sm={6} lg={12}>
              <Controller
                render={(propsInController) => (
                  <TextField
                    placeholder="ระบุปลายทาง"
                    label="ปลายทาง"
                    variant="outlined"
                    multiline
                    disabled
                    error={props.errors.toPlace ? true : false}
                    helperText={
                      props.errors.toPlace ? "กรุณาเลือกจุดปลายทาง" : false
                    }
                    fullWidth
                    value={propsInController.field.value}
                    onChange={propsInController.field.onChange}
                    onBlur={(e) => {
                      if (
                        props.getValues("fromPlaceReturn") === "" ||
                        props.getValues("fromPlaceReturn") === undefined
                      ) {
                        props.setValue("fromPlaceReturn", e.target.value);
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "15px",
                      },
                    }}
                  />
                )}
                name="toPlace"
                rules={{ required: true }}
                control={props.control}
                defaultValue=""
              />
            </Grid>
            <Grid item xs={12} sm={12} style={{ height: "400px" }}>
              <GoogleMap
                name="toPlace"
                inputProps={{ ...props.setValue("toPlace", dataTo) }}
                value={dataTo}
                handleGetDataFromGoogleMap={getDataFromGoogleMapToplace}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3}>
            <Controller
              render={(propsInController) => (
                <TextField
                  placeholder="ระบุจำนวนผู้โดยสาร"
                  label="จำนวนผู้โดยสาร"
                  fullWidth
                  error={props.errors.numberOfPassenger ? true : false}
                  helperText={
                    props.errors.numberOfPassenger
                      ? "กรุณากรอกจำนวนผู้โดยสาร"
                      : false
                  }
                  type="number"
                  min={1}
                  value={propsInController.field.value}
                  onChange={(e) => {
                    propsInController.field.onChange(e);
                    onChangePassenger(e);
                    // if(props.getValues("numberOfPassengerReturn") === "" || props.getValues("numberOfPassengerReturn") === undefined){
                    //   props.setValue("numberOfPassengerReturn",e.target.value);
                    // }
                  }}
                  InputProps={{
                    style: {
                      borderRadius: "15px",
                    },
                  }}
                />
              )}
              name="numberOfPassenger"
              control={props.control}
              rules={{ required: true }}
              defaultValue={1}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} sm={6} lg={3}>
              <Controller
                render={(propsInController) => (
                  <DatePicker
                    label="วันที่เดินทาง"
                    minDate={new Date()}
                    value={isDepartureDate}
                    onChange={(date) => {
                      if (
                        date > isReturnDate &&
                        props.watch("flight") === "twoWay"
                      ) {
                        setIsReturnDate(date);
                      }
                      setIsDepartureDate(date);
                      propsInController.field.onChange(date);
                    }}
                    inputFormat="dd MMMM yyyy"
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          ".MuiInputBase-root": { borderRadius: "15px" },
                        }}
                        fullWidth
                        {...params}
                      />
                    )}
                  />
                )}
                name="departureDate"
                defaultValue={new Date()}
                control={props.control}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12} sm={6} lg={3}>
            <Controller
              render={(propsInController) => (
                <FormControl fullWidth>
                  <InputLabel shrink>{"เวลาเริ่มออกรถ"}</InputLabel>
                  <Select
                    native
                    label="เวลาเริ่มออกรถ"
                    value={propsInController.field.value}
                    onChange={(e) => {
                      propsInController.field.onChange(e);
                      onChangeStartTime(e.target.value);
                    }}
                    style={{ borderRadius: "15px" }}
                  >
                    {generateTimeOptions().map((time, index) => {
                      if (time !== "23:30")
                        return (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        );
                    })}
                  </Select>
                </FormControl>
              )}
              name="startTime"
              control={props.control}
              defaultValue="00:00"
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Controller
              render={(propsInController) => (
                <FormControl fullWidth>
                  <InputLabel shrink>{"เวลาที่คืนรถ"}</InputLabel>
                  <Select
                    native
                    label="เวลาที่คืนรถ"
                    value={propsInController.field.value}
                    onChange={(e) => {
                      propsInController.field.onChange(e);
                      onChangeEndTime(e.target.value);
                    }}
                    style={{ borderRadius: "15px" }}
                  >
                    {endTimeOptions.map((time, index) => (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              name="endTime"
              control={props.control}
              defaultValue="00:00"
            />
          </Grid>
        </Grid>
      </Grid>
      {props.watch("flight") === "twoWay" && (
        <>
          <Text style={{ width: "100%", marginTop: "20px" }}>
            ขากลับ
            <Divider style={{ marginBottom: "20px" }} />
          </Text>
          <Grid
            container
            spacing={2}
            sx={(theme) => ({
              display: "flex",
              // justifyContent: "space-between",
              border: "1px solid #EEEEEE",
              backgroundColor: "#FBFCFC",

              marginBottom: "20px",
              padding: "25px 30px 25px 30px",
              boxShadow:
                "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

              // padding: "25px 30px 25px 30px",

              borderRadius: "15px",
              [theme.breakpoints.down("sm")]: {
                // padding: "25px 30px 25px 15px",
              },
              [theme.breakpoints.down("md")]: {
                // padding: "25px 30px 25px 15px",
              },
            })}
          >
            <Grid
              container
              spacing={2}
              style={{ height: "50%", width: "100%" }}
            >
              <Grid item xs={12} sm={6} lg={6}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  {"จุดรับ"}
                </InputLabel>
                <Grid item xs={12} sm={6} lg={12}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        placeholder="ระบุต้นทาง"
                        label="ต้นทาง"
                        error={props.errors.fromPlaceReturn}
                        helperText={
                          props.errors.fromPlaceReturn && "กรุณากรอกข้อมูล"
                        }
                        fullWidth
                        disabled
                        value={propsInController.field.value}
                        onChange={propsInController.field.onChange}
                        InputProps={{
                          style: {
                            borderRadius: "15px",
                          },
                        }}
                      />
                    )}
                    name="fromPlaceReturn"
                    control={props.control}
                    rules={{ required: true }}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} sm={12} style={{ height: "400px" }}>
                  <GoogleMap
                    name="fromPlaceReturn"
                    inputProps={{
                      ...props.setValue("fromPlaceReturn", dataFromReturn),
                    }}
                    value={dataFromReturn}
                    handleGetDataFromGoogleMap={
                      getDataFromGoogleMapFromReturnPlace
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  {"จุดส่ง"}
                </InputLabel>
                <Grid item xs={12} sm={6} lg={12}>
                  <Controller
                    render={(propsInController) => (
                      <TextField
                        placeholder="ระบุปลายทาง"
                        label="ปลายทาง"
                        fullWidth
                        disabled
                        value={propsInController.field.value}
                        onChange={propsInController.field.onChange}
                        InputProps={{
                          style: {
                            borderRadius: "15px",
                          },
                        }}
                      />
                    )}
                    name="toPlaceReturn"
                    control={props.control}
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} sm={12} style={{ height: "400px" }}>
                  <GoogleMap
                    name="toPlaceReturn"
                    inputProps={{
                      ...props.setValue("toPlaceReturn", dataToReturn),
                    }}
                    value={dataToReturn}
                    handleGetDataFromGoogleMap={
                      getDataFromGoogleMapToReturnplace
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  render={(propsInController) => (
                    <TextField
                      placeholder="ระบุจำนวนผู้โดยสาร"
                      label="จำนวนผู้โดยสาร"
                      fullWidth
                      type="number"
                      min={1}
                      value={propsInController.field.value}
                      onChange={propsInController.field.onChange}
                      InputProps={{
                        style: {
                          borderRadius: "15px",
                        },
                      }}
                    />
                  )}
                  name="numberOfPassengerReturn"
                  control={props.control}
                  defaultValue={1}
                />
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={12} sm={6} lg={3}>
                  <Controller
                    render={(propsInController) => (
                      <DatePicker
                        label="วันที่เดินทาง"
                        minDate={isDepartureDate}
                        value={isReturnDate}
                        onChange={(date) => {
                          setIsReturnDate(date);
                          propsInController.field.onChange(date);
                        }}
                        inputFormat="dd MMMM yyyy"
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            sx={{
                              ".MuiInputBase-root": { borderRadius: "15px" },
                            }}
                            {...params}
                          />
                        )}
                      />
                    )}
                    name="returnDate"
                    defaultValue={new Date()}
                    control={props.control}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  render={(propsInController) => (
                    <FormControl fullWidth>
                      <InputLabel shrink>{"เวลาเริ่มออกรถ"}</InputLabel>
                      <Select
                        native
                        label="เวลาเริ่มออกรถ"
                        value={propsInController.field.value}
                        onChange={(e) => {
                          propsInController.field.onChange(e);
                          onChangeStartTimeReturn(e.target.value);
                        }}
                        style={{ borderRadius: "15px" }}
                      >
                        {generateTimeOptions().map((time, index) => {
                          if (time !== "23:30")
                            return (
                              <option key={index} value={time}>
                                {time}
                              </option>
                            );
                        })}
                      </Select>
                    </FormControl>
                  )}
                  name="returnStartTime"
                  control={props.control}
                  defaultValue="00:00"
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  render={(propsInController) => (
                    <FormControl fullWidth>
                      <InputLabel shrink>{"เวลาที่คืนรถ"}</InputLabel>
                      <Select
                        native
                        label="เวลาที่คืนรถ"
                        value={propsInController.field.value}
                        onChange={(e) => {
                          propsInController.field.onChange(e);
                          onChangeEndTimeReturn(e.target.value);
                        }}
                        style={{ borderRadius: "15px" }}
                      >
                        {endTimeReturnOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  name="returnEndTime"
                  control={props.control}
                  defaultValue="00:00"
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default FormFlight;
